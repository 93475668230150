
import "@simonwep/pickr/dist/themes/classic.min.css";
import Pickr from "@simonwep/pickr";

import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomColorPicker",
  emits: ["change", "update:modelValue"],
  props: {
    modelValue: String,
    name: String,
    allowOpacity: {
      default: true,
    },
  },
  data() {
    return {
      pickr: null as null | Pickr,
      popupShowing: false,
    };
  },
  mounted() {
    this.pickr = Pickr.create({
      el: ".picker-input",
      theme: "classic",
      components: {
        preview: true,
        opacity: this.allowOpacity,
        hue: true,
        palette: true,
        interaction: {
          hex: true,
          input: true,
          cancel: true,
        },
      },
    });
    this.pickr.on("hide", this.colorChanged);
  },
  beforeUnmount() {
    this.pickr?.off("hide", this.colorChanged);
    this.pickr?.destroyAndRemove();
  },
  methods: {
    showPicker(event: any) {
      this.pickr?.setColor(this.modelValue || "white");
      this.pickr?.show();
      this.popupShowing = true;
    },
    colorChanged(event: any) {
      this.popupShowing = false;
      const hex = event.getColor().toHEXA().toString();
      this.$emit("update:modelValue", hex);
      this.$emit("change", hex);
    },
  },
});
